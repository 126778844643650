import React from "react";


const initialState = {
    taxCheckedDocuments: []
  }

export const GlobalStateContext = React.createContext(initialState)
export const GlobalDispatchContext = React.createContext()

 function taxCheckedDocumentsReducer(state, action) { 
     
     switch (action.type) {
         case "ADD_DOCUMENT":
             
            return {
                taxCheckedDocuments: [...state.taxCheckedDocuments, action.payload]
            }
            case "REMOVE_DOCUMENT":
            return{
                taxCheckedDocuments: [
                    ...state.taxCheckedDocuments.filter(document => document !== action.payload)
                ]
            }
         default:
            return state;
     }
 }

const GlobalContextProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(taxCheckedDocumentsReducer, initialState)
    return <GlobalStateContext.Provider value={state}> 
                <GlobalDispatchContext.Provider value={dispatch}>
                    {children}
                 </GlobalDispatchContext.Provider> 
            </GlobalStateContext.Provider>
}


export default GlobalContextProvider