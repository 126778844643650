exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-book-an-appointment-js": () => import("./../../../src/pages/book-an-appointment.js" /* webpackChunkName: "component---src-pages-book-an-appointment-js" */),
  "component---src-pages-buy-now-js": () => import("./../../../src/pages/buy-now.js" /* webpackChunkName: "component---src-pages-buy-now-js" */),
  "component---src-pages-canada-tax-checklist-js": () => import("./../../../src/pages/canada-tax-checklist.js" /* webpackChunkName: "component---src-pages-canada-tax-checklist-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tax-calculator-js": () => import("./../../../src/pages/tax-calculator.js" /* webpackChunkName: "component---src-pages-tax-calculator-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

